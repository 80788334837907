import React, { Component } from "react";
import { Row, Col, Modal, Skeleton } from "antd";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { Link } from "react-router-dom";

import NewsModal from "components/common/NewsModal";
import LandingBanner from "components/LandingBanner";

import article from "components/services/articleService";
import hotNews from "components/services/hotNewsService";
import jetso from "components/services/jetsoService";
import activity from "components/services/activityService";
import main from "components/services/mainService";

import { shuffle } from "components/utils/helper";

import "assets/css/pHome.module.scss";

import banner from "assets/image/parent/banner.jpg";
import banner3 from "assets/image/parent/banner3.jpg";
// import banner2 from "assets/image/parent/banner2.jpg";
import img1 from "assets/image/Group 8266.png";
import img2 from "assets/image/Group 8267.png";
import img3 from "assets/image/Group 8268.png";
import img4 from "assets/image/Path 10891.png";
import img5 from "assets/image/efun.png";

import user_API from "components/services/userService";

const url = `${process.env.REACT_APP_PD_URL}`;
class pHome extends Component {
  state = {
    memu: "ceo",
    visible: false,
    articleData: [],
    newsListData: [],
    newsDetail: {},
    topData: {},
    RewardData: {},
    courseData: [],
    planData: { totao: 0, rows: [] },
  };

  componentDidMount() {
    const {
      currentLocation: region,
      currentLanguage: { value: lang }
    } = this.props.route;

    const newLang = region === "hk" ? 'zh' : lang;

    main.getMenu(region, lang).then(ret => {
      this.setState({
        courseData: (ret || []).filter(_ => !_.parent_id)
      })
    })

    article.getLatest(newLang).then(ret => {
      this.setState({
        articleData: ret.rows.filter((_, i) => {
          return newLang === 'cn' ? i < 4 : _
        })
      });
    })

    // hotNews.newsList().then(ret => {
    //   this.setState({ newsListData: ret.filter((_, index) => index < 4) });
    // })

    // activity.top().then(ret => {
    //   this.setState({ topData: ret });
    // })

    // jetso.getList().then(ret => {
    //   this.setState({ RewardData: this.RandomNumBoth(ret.rows, 1)[0] });
    // })

    main.getDisplayList({ region, limit: 10, menu: 119, platform: 12, lang: 'zh' })
      .then(({ total, rows }) => {
        this.setState({
          planData: { total, rows: rows.filter((_, i) => i < 4) }
        });
      })
  }
  //隨機顯示
  RandomNumBoth(arr, maxNum) {
    let _ret = shuffle(arr);
    return _ret.slice(0, maxNum);
  }

  handleClickNewsList = id => {
    this.setState({ visible: true });
    hotNews.newsDetail(id).then(ret => {
      this.setState({ newsDetail: ret });
    })
  };

  handleClickTop = id => {
    this.setState({ visible: true });
    activity.info(id).then(ret => {
      this.setState({ newsDetail: ret });
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      newsDetail: {}
    });
  };

  DepositParentId = (item) => {
    // console.log(this.props);
    this.props.PARENTID({ id: item.id, alias: item.alias })
  };

  lang = value => this.props.translations.initDone && intl.get(value);

  render() {
    const {
      currentLocation,
      currentLanguage: { value: lang },
      locationUrl
    } = this.props.route;

    const {
      articleData,
      newsListData,
      newsDetail,
      topData,
      RewardData,
      courseData,
      planData
    } = this.state;

    // 根据长度判断xs 占几格
    const articleLenghtXs = articleData.length;
    const courseLenghtXs = courseData.length;
    // 根据左边控制右边遍历几个item
    const sliceNumber = [articleLenghtXs + 1, 3, 2, 1]
    // console.log(user_API.getHomeURL());
    // console.log(user_API.getHomeURL().indexOf('parent'));
    // console.log(user_API.getHomeURL().slice(0, user_API.getHomeURL().indexOf('parent')));

    // const judge = user_API.getHomeURL().indexOf('parent');
    // const restructure = judge !== -1 ? user_API.getHomeURL().slice(0, judge) : user_API.getHomeURL();

    return (
      <>
        <NewsModal page='landing' />
        {/* 轮播图 */}
        <LandingBanner
          img={currentLocation === "cn" ? [
            { file: banner3 },
          ] : [
            // { file: banner2, link: 'https://www.commchest.org/zh_hk/events/view/147' },
            { file: banner },
          ]}
        />
        <Row className="pHome_container" type="flex" justify="center">
          <Col
            className="pArticle_container"
            xl={20}
            xs={22}
          >
            <Row gutter={20} type="flex" justify="start" >
              {currentLocation === "cn" ? null :
                <Col className="pArticle_pic" md={courseLenghtXs * 6} xs={24}>
                  <h2>
                    <img src={img1} alt="" height="30px" />
                    &nbsp;{this.lang("pHome.title.course")}
                  </h2>
                  <Row gutter={[20, 20]} type="flex" justify="start">
                    <Skeleton loading={!courseData.length} active>
                      {courseData.map(item => {
                        return (
                          <Col key={item.id} xs={24 / courseLenghtXs}>
                            <Link
                              onClick={() => {
                                this.DepositParentId(item);
                              }}
                              to={`${locationUrl}parent/${item.alias}`}
                            >
                              <img
                                className="pArticle_img"
                                src={item.cover}
                                alt=""
                              />
                            </Link>
                          </Col>
                        )
                      })}
                    </Skeleton>
                  </Row>
                </Col>}
              <Col className="pArticle_list" md={24 - (courseLenghtXs * 6)} xs={24}
                style={currentLocation === "cn" && { width: "100%", textAlign: "center" }}>
                <div style={{ overflow: "hidden" }}>
                  {
                    sliceNumber[courseLenghtXs] !== 1 ?
                      <h2 style={{ float: "left" }}>
                        <img src={img2} alt="" height="30px" />
                        &nbsp;
                        {this.lang("pHome.title.parentChild")}
                      </h2>
                      :
                      <a href={`${url}`} target="blank">
                        <h2 style={{ float: "left" }}>
                          <img src={img2} alt="" height="30px" />
                          &nbsp;
                          {this.lang("pHome.title.parentChild")}
                        </h2>
                      </a>
                  }
                  {
                    sliceNumber[courseLenghtXs] !== 1 &&
                    <p style={{ float: "right" }}>
                      Powered by{" "}
                      <a href={`${url}`} target="blank">
                        <span style={{ color: "#ff65c2" }}>Parents</span>{" "}
                        <span style={{ color: "#2e89ff" }}>Daily</span>
                      </a>
                    </p>
                  }

                </div>
                <Row className="pArticle_list_bg">
                  <Skeleton loading={!articleData.length} active>
                    {articleData.slice(0, sliceNumber[courseLenghtXs]).map(item => {
                      return (
                        <a
                          href={`${url}/all/${item.id}`}
                          target="blank"
                          key={item.id}
                          style={{ color: "rgba(0, 0, 0, 0.65)" }}
                        >
                          <Col
                            style={{ padding: "0 10px" }}
                            xs={24 / sliceNumber[courseLenghtXs]}
                          >
                            <div className="img_Box">
                              <img src={item.thumbnail} alt="" />
                            </div>
                            <div>
                              <p className="list_text">{item.title}</p>
                            </div>
                          </Col>
                        </a>
                      )
                    })}
                  </Skeleton>
                </Row>
              </Col>
            </Row>
          </Col>
          {(currentLocation === 'hk' || currentLocation === 'mo') && (
            <Col
              xl={20} xs={22}
              className="plan_container"
              style={{
                backgroundImage: `url(${require(`assets/image/parent/plan_${lang}.png`)})`
              }}
              onClick={() => window.open(`${process.env.REACT_APP_PD_URL}/transition`)}
            >
              <Row
                type="flex"
                justify="end"
                // align="middle" 
                style={{
                  height: '100%',
                }}
              >
                <Col lg={11} xs={24}>
                  <Row
                    type="flex"
                    gutter={[30, 30]}
                    className="content_wrap"
                  >
                    <Skeleton loading={!planData.rows.length} active>
                      {planData.rows.map(item => (
                        <Col
                          lg={12}
                          xs={24}
                          key={item.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(`${url}/all/${item.id}`);
                          }}
                        >
                          <div
                            className="plan_img"
                            style={{
                              backgroundImage: `url(${item.thumbnail})`
                            }}
                          ></div>
                          <div className="plan_title">{item.title}</div>
                        </Col>
                      ))}
                    </Skeleton>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {/* new */}
          {/* <Col className="news_container" xl={20} xs={22}>
            <Row gutter={20}>
              <Col className="news_list" lg={8} md={8} sm={12} xs={24}>
                <h2>
                  <img src={img4} height="30px" alt="" />
                  &nbsp;{this.lang("pHome.title.news")}
                </h2>
                <div className="box">
                  <Skeleton loading={!newsListData.length} active>
                    <ul>
                      {newsListData.map(item => (
                        <li
                          key={item.id}
                          onClick={() => this.handleClickNewsList(item.id)}
                        >
                          <i />
                          {item.title}
                        </li>
                      ))}
                    </ul>
                  </Skeleton>
                </div>
                <Modal
                  width={800}
                  visible={this.state.visible}
                  onCancel={this.handleCancel}
                  footer={null}
                  centered
                  destroyOnClose={true}
                >
                  <Skeleton loading={!Object.keys(newsDetail).length} active paragraph={{ rows: 6 }}>
                    <div>
                      <p align="center">
                        <font size="5">
                          {newsDetail.title || newsDetail.name}
                        </font>
                      </p>
                      <div
                        className="dangerously"
                        dangerouslySetInnerHTML={{
                          __html:
                            newsDetail[
                              newsDetail.showtype ? "content" : "details"
                            ]
                        }}
                      />
                    </div>
                  </Skeleton>
                </Modal>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <h2>
                  <img src={img3} height="30px" alt="" />
                  &nbsp;
                  {this.lang("pHome.title.activity")}
                </h2>
                <div className="box pic_box">
                  <Skeleton loading={!Object.keys(topData).length} active>
                    <div onClick={() => this.handleClickTop(topData.id)}>
                      <div className="img_Box pic">
                        <img src={topData.img} alt="" />
                      </div>
                      <span>{topData.name}</span>
                    </div>
                  </Skeleton>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <img src={img5} height="45px" alt="" />
                <div className="box pic_box">
                  <Skeleton loading={!Object.keys(RewardData).length} active>
                    <a
                      href={`${url}/efunfun/product_info/${RewardData.id}`}
                      target="blank"
                    >
                      <div className="img_Box pic">
                        <img src={RewardData.img} alt="" />
                      </div>
                      <span>{RewardData.name}</span>
                    </a>
                  </Skeleton>
                </div>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </>
    );
  }
}

function mapStateToProps({ route, translations }) {
  return {
    route,
    translations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    PARENTID: payload => dispatch({ type: "PARENTID", payload })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(pHome);
