import React from "react";
import user_API from "components/services/userService";
import intl from "react-intl-universal";

const WithSubMenuConfig = (WrappedComponent) => {
  return class withSubMenuConfig extends React.Component {
    lang = (value) => {
      return (
        this.props.translations.initDone &&
        intl.get(`personalCenter.menu.${value}`)
      );
    };
    render() {
      const { ...passThroughProps } = this.props;
      const isParent = user_API.getType() === "PARENT";
      const config = [
        {
          label: this.lang("personal"),
          icon: "personal",
          url: "user/personal",
        },
        {
          label: this.lang("redemption"),
          icon: "redeem",
          url: "redemption",
        },
        {
          label: this.lang("favorite"),
          icon: "favorite",
          url: "user/favorite",
          disabled: isParent,
        },
        {
          label: this.lang("history"),
          icon: "history",
          url: "user/history",
          disabled: isParent,
        },
        {
          label: this.lang("statistics"),
          icon: "statistics",
          url: "user/statistics",
          disabled: isParent,
        },
        {
          label: this.lang("achievement"),
          icon: "achievement",
          url: "user/achievement",
          disabled: isParent,
        },
        {
          label: this.lang("coin"),
          icon: "coin",
          url: "user/coin",
        },
        {
          label: this.lang("game"),
          icon: "game",
          url: "user/game",
          disabled: isParent,
        },
        {
          label: this.lang("setting"),
          icon: "setting",
          url: "user/setting",
          // disabled: isParent,
        },
        {
          label: this.lang("intranet"),
          icon: "intranet",
          url: "intranet",
          disabled: isParent || !user_API.checkPermitKid(),
          target: "_blank",
        },
        {
          label: this.lang("meetings"),
          icon: "meetings",
          url: "meetings",
          disabled: isParent || !user_API.checkPermitKid(),
          target: "_blank",
        },
      ];
      const retConfig = user_API.getType()
        ? config.filter((_) => !_.disabled)
        : [];
      return <WrappedComponent menuConfig={retConfig} {...passThroughProps} />;
    }
  };
};

export default WithSubMenuConfig;
