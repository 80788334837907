import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import intl from "react-intl-universal";
import { Row, Col, Button, message, Alert } from "antd";

import logo from "assets/image/zoomCalendar/logo.png";

import Calendar from "./components/Calendar";
import Editor from "./components/Editor";

import userService from "components/services/userService";
import authService from "components/services/authService";

import { TEST_SCHOOL } from "config/course.json";

import zoom from "assets/image/zoomCalendar/zoom.png";

const url = process.env.REACT_APP_API_URL_ZOOM;

const ZoomCalendar = () => {
  const dispatch = useDispatch();

  const { user: { id }, translations: { initDone } } = useSelector(state => state);

  const { search } = useLocation();

  const [ loading, setLoading ] = useState(false);

  const [ hasPermission, setHasPermission ] = useState(false);

  const lang = (value) => {
    return initDone && intl.get("zoomCalendar." + value);
  }

  const getUserToken = useCallback(() => {
    const code = search.split("=")[1];
    setLoading(true);
    authService.getUserToken(code).then(async () => {
      setHasPermission(true);
      // 登入 ZOOM 成功后，更新 user 數據
      dispatch({ type: "INIT", payload: await userService.getUserData() });
    }).catch(error => {
      console.log(error);
      if(error && Number(error.result) === 212) {
        const msg = error.data ? `${lang('error_msg_binding_left')}${error.data}${lang('error_msg_binding_right')}` : lang('error_msg_binding');
        message.error(msg);
      }
    }).finally(() => {
      setLoading(false);
    })
  }, [search])

  useEffect(() => {
    if(userService.getType() === "STAFF") {
      setLoading(true);
      // 判斷有無登入 ZOOM
      authService.checkToken()
        .then(ret => {
          setLoading(false);
          setHasPermission(true);
        })
        .catch(error => {
          if(search) {
            getUserToken();
            return;
          }
          setLoading(false);
        })
    }
  }, [])

  const [visible, setVisible] = useState(false);

  const merchantID = userService.getMerchantID();

  return (
    <Row style={{ maxWidth: "1200px", margin: "15px auto" }}>
      {/* <Button onClick={getUserToken}>testing</Button> */}
      <Row type="flex" justify="center" align="middle">
        <Col>
          <img src={logo} style={{}} alt="" />
        </Col>
        <Col>
          <h1
            style={{
              color: "#3b7191",
              fontSize: "30px",
              fontWeight: "bold",
              marginBottom: 0,
            }}
          >
            {lang("title_table")}
          </h1>
        </Col>
        <Col xs={24} style={{marginTop: 10}}>
          <Alert message={lang("info_msg_title")} type="info" showIcon />
        </Col>
        <Col xs={24} style={{textAlign: 'right', padding: '10px 0'}}>
          {userService.getType() === "STAFF" && (
            loading ? (
              <Button 
                // style={{position: 'absolute', right: 0}}
                icon="loading"
                type="primary"
                loading 
              />
            ) : (
              <>
                {hasPermission ? (
                  <Button 
                  // style={{position: 'absolute', right: 0}} 
                  icon="plus" type="primary" onClick={() => setVisible(true)}>
                    {lang("btn_add")}
                  </Button>
                ) : (
                  <Button 
                    // style={{position: 'absolute', right: 0}} 
                    type="primary"
                    href={url}
                  >
                    <img
                      style={{ width: 25, marginRight: 10}}
                      src={zoom}
                      alt="zoom"
                    /> {lang("btn_zoom")}
                  </Button>
                )}
                <Editor 
                  visible={visible}
                  onCancel={() => setVisible(false)}
                />
              </> 
            )
          )}
        </Col>
      </Row>
      <Calendar />
    </Row>
  );
};

export default ZoomCalendar;
