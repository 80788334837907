import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Layout,
  Icon,
  Avatar,
  Row,
  Col,
  Typography,
  Alert,
  Dropdown,
  Menu,
  Statistic,
  Button,
  Drawer,
} from "antd";
import MediaQuery from "react-responsive";
import intl from "react-intl-universal";
import MyDrawer from "components/common/Drawer";
import LanguageSwitch from "components/common/LanguageSwitch";
import LandingPopup from "components/LandingPopup";
import Notification from "components/notification";
import Download from "./Download";

import Language from "components/language";

import user_API from "components/services/userService";

import style from "assets/css/layout.module.scss";

import { isMobile } from "components/utils/type";

import NotLogDrawer from "./NotLogDrawer";

const { Header } = Layout;
const { Text } = Typography;

/**
 * 头部组件
 *    动态渲染 用户名 如果登陆了 就会显示 name
 *    如果没有登录 渲染 登录按钮
 *
 * @export 头部组件
 * @class Header
 * @extends {Component}
 */
class MyHeader extends Component {
  //用户抽屉状态
  state = {
    visible: false,
    studentVisible: false,
    loginVisible: false,
    swapUser: false,
    downloadVisible: null, //sessionStorage.getItem("eviDownload") || "block",
    visibleDrawer: false,
  };

  headerRef = React.createRef();

  lang = (value) => this.props.translations.initDone && intl.get(value);

  openDrawer = () => {
    this.setState({
      visibleDrawer: true,
    });
  };

  onDrawerClose = () => {
    this.setState({
      visibleDrawer: false,
    });
  };

  //显示用户抽屉
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  //关闭用户抽屉
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  UserOpen = () => {
    this.setState({ swapUser: true });
  };
  visibleSwapUser = () => {
    this.setState({ visible: true, swapUser: true });
  };

  setEmptyHeight = () => {
    const height = this.headerRef.current.offsetHeight;
    this.props.setEmptyHeight(height);
  };

  handleClose = () => {
    this.setState({ downloadVisible: "none" });
    sessionStorage.setItem("eviDownload", "none");
  };

  componentDidMount() {
    this.setEmptyHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    const { global: prevGlobal } = prevProps;

    const {
      global: { drawerVisible },
    } = this.props;

    if (drawerVisible !== prevGlobal.drawerVisible && drawerVisible === false) {
      this.setState({ visible: false });
    }

    if (
      prevState.downloadVisible !== this.state.downloadVisible ||
      prevProps.isHealth !== this.props.isHealth
    ) {
      this.setEmptyHeight();
    }
  }

  render() {
    const { locationUrl, route, isHealth, location } = this.props;

    // const urlParams = new URLSearchParams(location.search);
    // const key = urlParams.get("app");
    const isHidden = (location.search || "").includes("app=");
    const isRegister = location.pathname.includes("/register");
    const isWebApp = location.pathname.includes("/web_to_app");

    const {
      id: uID,
      img: userAvatar,
      full_name,
      type,
      latest_version,
    } = this.props.user;
    const btnStyle = {
      height: "32px",
      padding: "0 16px",
      marginLeft: "0.5rem",
      fontSize: "16px",
      border: "none",
      textAlign: "center",
    };

    const LinkAdapter =
      !uID || user_API.getType() === "STAFF"
        ? Link
        : (props) => <React.Fragment>{props.children}</React.Fragment>;

    const AdapterBtn = () => (
      <LinkAdapter
        to={
          !uID || user_API.getType() === "STAFF"
            ? `/${route.currentLocation}/${route.currentLanguage.url}/${
                route.tag === "parent" ? "" : "parent"
              }`
            : "#"
        }
      >
        <div
          className={style.btnWidth}
          style={{
            cursor: "pointer",
            display: "inline-block",
            paddingTop: !this.state.visibleDrawer ? "8px" : "0px",
          }}
          onClick={() => {
            if (uID && user_API.getType() !== "STAFF") {
              this.setState({ visible: true, swapUser: true });
            }
          }}
        >
          {(!uID || user_API.getType() === "STAFF"
            ? route.tag === "parent"
            : type === "PARENT") && (
            <div
              style={{
                border: "none",
                padding: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {!this.state.visibleDrawer && (
                <img
                  src={require(`assets/image/button/kid_icon.png`)}
                  alt=""
                  style={{ width: 26, height: 26, marginRight: 8 }}
                />
              )}
              <span style={{ fontSize: 16, color: "#000", fontWeight: "400" }}>
                {this.lang("home.publicMsg.header.child")}
              </span>
            </div>
          )}
          {(!uID || user_API.getType() === "STAFF"
            ? route.tag !== "parent"
            : type !== "PARENT") && (
            <div
              style={{
                border: "none",
                padding: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {!this.state.visibleDrawer && (
                <img
                  src={require(`assets/image/button/parent_icon.png`)}
                  alt=""
                  style={{ width: 26, height: 26, marginRight: 8 }}
                />
              )}
              <span style={{ fontSize: 16, color: "#000", fontWeight: "400" }}>
                {this.lang("home.publicMsg.header.parents")}
              </span>
            </div>
          )}
        </div>
      </LinkAdapter>
    );

    return (
      <>
        <div
          ref={this.headerRef}
          className={style.headerWrap}
          style={{
            // display: key === "parentsdaily" ? "none" : "block",
            display: isHidden || isWebApp ? "none" : "block",
          }}
        >
          {/* <div style={{ display: !!uID || !!isRegister ? "none" : "block" }}>
            <Language />
          </div> */}

          {!isHealth && (
            <Alert
              message={this.lang("general.msg.header_busy")}
              type="warning"
              banner
              closable
              onClose={this.props.setHealth}
            />
          )}
          <Header className={style.header}>
            <div className={style.inner}>
              <Row
                type="flex"
                align="middle"
                justify="start"
                gutter={{ md: 25, sm: 10, xs: 10 }}
              >
                {/* logo */}
                <MediaQuery minWidth={992}>
                  <Col>
                    <Link
                      to={
                        (!!user_API.$$user && user_API.getHomeURL()) ||
                        locationUrl
                      }
                    >
                      <img
                        src={require("assets/image/logo.png")}
                        alt="logo"
                        style={{ width: "100%", cursor: "pointer" }}
                      />
                    </Link>
                  </Col>
                </MediaQuery>
                <MediaQuery maxWidth={992}>
                  {/* 手機版logo */}
                  <Col>
                    <Link
                      to={
                        (!!user_API.$$user && user_API.getHomeURL()) ||
                        locationUrl
                      }
                    >
                      <img
                        src={require("assets/image/logo_s.png")}
                        alt="logo"
                        style={{ width: "70px", cursor: "pointer" }}
                      />
                    </Link>
                  </Col>
                </MediaQuery>
                {/* 占位元素 */}
                <Col style={{ flex: "1 0" }} />
                {!uID && !isRegister && (
                  <Col lg={10} xl={12} md={0} xs={0} sm={0}>
                    <Language />
                  </Col>
                )}
                <MediaQuery maxWidth={992}>
                  <Col>
                    {route.currentLocation != "tw" && (
                      <Button
                        style={{
                          backgroundColor: "#38AD52",
                          color: "#fff",
                          borderRadius: 20,
                          height: 33,
                          border: "none",
                        }}
                      >
                        <Link to={`${route.locationUrl}web_to_app`}>
                          {this.lang("home.publicMsg.web.use")}
                        </Link>
                      </Button>
                    )}
                  </Col>
                </MediaQuery>
                {!isRegister && (
                  <MediaQuery minWidth={992}>
                    {!!uID && user_API.checkPermitKid() && (
                      <Col>
                        <Link
                          to={`${locationUrl}meetings`}
                          className={style.btnWidth}
                          style={{ display: "inline-block" }}
                        >
                          {/* <img
                            style={{ height: "100%" }}
                            src={require(`assets/image/button/meetings_${route.currentLanguage.value}.png`)}
                            alt=""
                          /> */}
                          <Button style={{ border: "none", padding: 0 }}>
                            <img
                              style={{ height: 27 }}
                              src={require(`assets/image/button/zoom_icon.png`)}
                              alt=""
                            />
                            &nbsp;
                            {this.lang("zoomCalendar.title_table")}
                          </Button>
                        </Link>
                      </Col>
                    )}
                    {!uID && (
                      <Col>
                        {!uID || user_API.getType() === "STAFF" ? (
                          <AdapterBtn />
                        ) : (
                          <Button
                            style={{ border: 0, padding: 0 }}
                            onClick={() => this.setState({ swapUser: true })}
                          >
                            <img
                              src={require(`assets/image/button/parent_icon.png`)}
                              alt=""
                            />
                            &nbsp;
                            {this.lang("home.publicMsg.header.switchUser")}
                          </Button>
                        )}
                      </Col>
                    )}
                  </MediaQuery>
                )}
                {/* 會員登入 */}
                {!uID && !isRegister && (
                  <React.Fragment>
                    <Col>
                      <LandingPopup
                        ref="LandingPopup"
                        loginpic={"login"}
                        type={"login"}
                        style={{ ...btnStyle }}
                        closable={true}
                        Modalstyle={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "15px 20px",
                        }}
                      />
                    </Col>
                  </React.Fragment>
                )}
                <MediaQuery minWidth={992}>
                  {!uID && !isRegister && (
                    <Col>
                      <Button
                        style={{
                          backgroundColor: "#45A735",
                          color: "#fff",
                          borderRadius: 20,
                          height: 33,
                          border: "none",
                        }}
                      >
                        <Link to={`${route.locationUrl}register`}>
                          {this.lang("home.publicMsg.header.register")}
                        </Link>
                      </Button>
                    </Col>
                  )}
                </MediaQuery>

                {/* 通知 */}
                {/* {!!uID && !isRegister && latest_version === "Y" && ( */}
                {!!uID && !isRegister && (
                  <Col>
                    <Notification />
                  </Col>
                )}
                <Col
                  // style={{ display: !!uID || !!isRegister ? "none" : "block" }}
                  style={{ display: "none" }}
                >
                  <LanguageSwitch />
                </Col>
                {!!uID && !isRegister && (
                  <Col
                    id="avatar"
                    style={{ cursor: "pointer", textAlign: "right" }}
                    onClick={this.showDrawer}
                  >
                    <Avatar
                      size="large"
                      src={
                        (!!userAvatar && `${userAvatar}?t=${Math.random()}`) ||
                        null
                      }
                      icon={!!userAvatar ? "" : "user"}
                      style={{
                        backgroundColor: !userAvatar ? "#efefef" : "white",
                        color: "black",
                      }}
                    />
                    <div className={"d-inline-flex hidden visible-sm"}>
                      <Text className={style["avatar-text"]}>{full_name}</Text>
                    </div>
                    <Icon
                      className={"d-inline-flex hidden visible-sm"}
                      type="caret-right"
                    />
                    {!!uID && !isRegister && (
                      <MediaQuery maxWidth={555}>
                        <span style={{ marginLeft: "8px" }}>
                          <Icon type="menu" />
                        </span>
                      </MediaQuery>
                    )}
                  </Col>
                )}
                <MediaQuery maxWidth={992}>
                  {/* 手機版 menu */}
                  {!isRegister && !uID && (
                    <>
                      <Col>
                        <Icon type="menu" onClick={this.openDrawer} />
                      </Col>
                    </>
                  )}
                </MediaQuery>
              </Row>
            </div>

            {/* 用户抽屉 */}
            <MyDrawer
              visible={this.state.visible}
              onClose={this.onClose}
              locationUrl={locationUrl}
              swapUser={this.state.swapUser}
              swapUserOnClose={() => {
                this.setState({ visible: false, swapUser: false }, () => {
                  // cookie.setCookie(`${display_id}-show-parent-manager`, true);
                });
              }}
              swapUserOpen={this.UserOpen}
              $location={this.props.$location}
            />
            {/* 未登入抽屉 */}
            <Drawer
              placement="right"
              closable={false}
              onClose={this.onDrawerClose}
              visible={this.state.visibleDrawer}
              style={{ height: "100%" }}
            >
              {!uID && !isRegister && (
                <div style={{ marginBottom: "20px", marginLeft: "-5px" }}>
                  <React.Fragment>
                    <Col>
                      <LandingPopup
                        ref="LandingPopup"
                        loginpic={"login"}
                        type={"login"}
                        style={{ ...btnStyle }}
                        closable={true}
                        Modalstyle={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "15px 20px",
                        }}
                      />
                    </Col>
                  </React.Fragment>
                </div>
              )}
              <NotLogDrawer
                uID={isRegister}
                isRegister={isRegister}
                type={type}
                visibleDrawer={this.state.visibleDrawer}
                UserOpen={this.UserOpen}
                visibleSwapUser={this.visibleSwapUser}
              />
            </Drawer>
          </Header>
        </div>
      </>
    );
  }
}

/** redux 獲得全局數據
 * route  route data (url, language) --暫時沒有用到
 * user  user data (用戶數據)
 */
function mapStateToProps({ auth, route, user, translations, global }) {
  return { auth, route, user, translations, global };
}

export default connect(mapStateToProps)(withRouter(MyHeader));
