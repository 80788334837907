import React from "react";

import CoinPage from "../page/CoinPage";
import PersonalPage from "../page/PersonalPage";
import FavoritePage from "../page/FavoritePage";
import HistoryPage from "../page/HistoryPage";
import AchievementPage from "../page/AchievementPage";
import SettingPage from "../page/SettingPage";
import GamePage from "../page/GamePage";
import StatisticsPage from "../page/StatisticsPage";

const page = [
  {
    title: "個人資料",
    icon: "personal",
    url: 'user/personal',
    components: <PersonalPage />,
  },
  {
    title: "我的最愛",
    icon: "favorite",
    url: 'user/favorite',
    components: <FavoritePage />,
  },
  {
    title: "觀看記錄",
    icon: "history",
    url: 'user/history',
    components: <HistoryPage />,
  },
  {
    title: "使用統計",
    icon: "statistics",
    url: 'user/statistics',
    components: <StatisticsPage />,
  },
  {
    title: "我的成就",
    icon: "achievement",
    url: 'user/achievement',
    components: <AchievementPage />,
  },
  {
    title: "獎勵活動",
    icon: "coin",
    url: 'user/coin',
    components: <CoinPage />,
  },
  {
    title: "開心天地",
    icon: "game",
    url: 'user/game',
    components: <GamePage />,
  },
  {
    title: "設定",
    icon: "setting",
    url: 'user/setting',
    components: <SettingPage />,
  },
  {
    title: "内聯網",
    icon: "intranet",
    url: 'intranet',
    components: <></>,
  },
  {
    title: "實時網上課堂",
    icon: "meetings",
    url: 'meetings',
    components: <></>,
  },
];

export default page;
