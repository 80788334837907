import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from "react-intl-universal";

import { List, Avatar, Icon, Button, message } from 'antd';

import user from "components/services/userService";
import auth from "components/services/authService";

import KidBind from "components/common/login/account/kidBind";

const BindingStatus = ({ status }) => {
  const { translations,route:{currentLocation} } = useSelector(state => state);

  const lang = (value) => translations.initDone && intl.get(`personalCenter.setting.${value}`);

  return status ? (
    <div>
      <Icon
        type="check-circle"
        theme="twoTone"
        twoToneColor="#52c41a"
        style={{ marginRight: 5, verticalAlign: 'middle' }}
      />
      {currentLocation==="cn"?lang('binding'):lang('connected')}
    </div>
  ) : (
    <div>
      <Icon
        type="close-circle"
        theme="twoTone"
        twoToneColor="#eb2f96"
        style={{ marginRight: 5, verticalAlign: 'middle' }}
      />
      {currentLocation==="cn"?lang('unbinding'):lang('unconnected')}
    </div>
  )
}

const zoomUrl = process.env.REACT_APP_API_URL_ZOOM;

const Binding = () => {
  const dispatch = useDispatch();
  const { user_token = [] } = useSelector(state => state.user);
  const { translations } = useSelector(state => state);
  const binding = useSelector(state => state.route.currentLocation)
  const [loading, setLoading] = useState(false);

  const [kidBindVisible, setKidBindVisible] = useState(false);

  const lang = (value) => translations.initDone && intl.get(`personalCenter.setting.${value}`);

  // const getStatus = (type) => user_token.find(_ => _.type_name === type && _.status === 'VALID');

  const updateUserData = async () => {
    await user.me(true).then(payload => dispatch({ type: "INIT", payload }));
  }

  const handleBind = (type) => {
    type === 'KID' && setKidBindVisible(true);
    type === 'ZOOM' && window.open(zoomUrl);
  }

  const handleUnbind = (type) => {
    setLoading(true);
    auth.accountUnbind(type).then(async () => {
      await updateUserData();
    }).catch(error => {
      console.log(error);
      message.error("解綁失敗");
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <List
        loading={false}
        itemLayout="horizontal"
        dataSource={user_token}
        renderItem={item => (
          <List.Item
            actions={[
              item.status === 'NOT_BIND' ? (
                <Button
                  type="link"
                  onClick={() => handleBind(item.type_name)}
                >
                  {binding === "cn" ? lang('ImmediatelyBinding') : lang('connect')}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => handleUnbind(item.type_name)}
                  loading={loading}
                >
                  {binding === "cn" ? lang('removeBinding') : lang('disconnected')}
                </Button>
              )
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.type_image} />}
              title={item.type_name}
            />
            <BindingStatus status={item.status !== 'NOT_BIND'} />
          </List.Item>
        )}
      />
      {/* <List.Item
          actions={[
            getStatus('KID') ? (
              <Button 
                type="link"
                onClick={() => handleUnbind("KID")}
                loading={loading}
              >
                {lang('disconnected')}
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => setKidBindVisible(true)}
              >
                {lang('connect')}
              </Button>
            )
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={'https://hk.evi.com.hk/assets/images/logo_s.png'} />}
            title={"KID"}
          />
          <BindingStatus status={getStatus('KID')} lang={lang} />
        </List.Item>
        {user.getType() !== "STUDENT" && (
          <List.Item
            actions={[
              getStatus('ZOOM') ? (
                <Button 
                  type="link"
                  onClick={() => handleUnbind('ZOOM')}
                  loading={loading}
                >
                  {lang('disconnected')}
                </Button>
              ) : (
                <Button 
                  type="link"
                  href={zoomUrl}
                  target="blank"
                >
                  {lang('connect')}
                </Button>
              )
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={''} />}
              title={"ZOOM"}
            />
            <BindingStatus status={getStatus('ZOOM')} />
          </List.Item>
        )}
      </List> */}
      <KidBind
        visible={kidBindVisible}
        onCancel={() => setKidBindVisible(false)}
        onSubmitAfter={async () => {
          await updateUserData();
          setKidBindVisible(false);
        }}
      />
    </>
  )
}

export default Binding;