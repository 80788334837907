import React, { Component } from "react";
import { connect } from "react-redux";
import intl from "react-intl-universal";

import basicStyle from "assets/css/contact/basic.module.scss";

import twLogo from '../../../assets/image/志雄科技LOGO_外部白框.png';

const transitionPosition = `otherPage.about`;

// const about = ({ translations: { initDone } }) => {

//   return (
//     <div className={basicStyle.center}>
//       {intl.getHTML(`${transitionPosition}`)}
//     </div>
//   );
// };

// export default about;

class about extends Component {

  render() {
    const { route } = this.props;
    return (
      <div className={basicStyle.center} style={{ textAlign: 'justify' }}>
        <h1>{intl.get(`${transitionPosition}.title`)}</h1>
        <div>
          {
            route.currentLocation === 'cn' ?
              <div>
                {/* 大陆版使用这个模板 */}
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph1`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph1`)}</div>
                <br />
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph4`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph4`)}</div>
                <br />
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph5`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph5`)}</div>
              </div> :
              <div>
                {/* 香港 及其 其他地区使用这个模板 */}
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph1`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph1`)}</div>
                <br />
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph2`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph2`)}</div>
                <br />
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph3`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph3`)}</div>
                <br />
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph4`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph4`)}</div>
                <br />
                <div>{intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}.Paragraph5`) || intl.getHTML(`${transitionPosition}.detail.hk.Paragraph5`)}</div>
              </div>
          }
          {/* {intl.getHTML(`${transitionPosition}.detail.${route.currentLocation}`) || intl.getHTML(`${transitionPosition}.detail.hk`)} */}
          <br></br>
          <br></br>
          {route.currentLocation === 'tw' &&
            <div>
              <div style={{ paddingBottom: '8px' }}>
                <img src={twLogo} alt="" style={{ width: '100%', maxWidth: '250px' }} />
              </div>
              {intl.getHTML(`${transitionPosition}.otherPage_tw`)}
            </div>
          }
        </div>
      </div>
    );
  }

}

function mapStateToProps({ route, user, translations }) {
  return {
    route,
    user,
    translations
  };
}

export default connect(mapStateToProps)(about);
